import request from '../until/request.js'
// 管理获取公告列表
export const getSchArt = data => {
  return request.get('/admin/MgetSchArt', data)
}
// 访客获取公告列表
export const getSchArtApi = data => {
  return request.get('/api/getSchArt')
}
// 管理获取详细公告列表
export const getDetailSchArt = data => {
  return request.get('/admin/MgetDetailSchArt?id=' + data)
}
// 访客获取详细公告列表
export const getDetailSchArtApi = data => {
  return request.get('/api/getDetailSchArt?id=' + data)
}

// 发布公告
export const addSchArt = data => {
  return request.post('/admin/addSchArt', data)
}
// 编辑公告
export const editSchArt = data => {
  return request.post('/admin/editSchArt', data)
}
// 下架公告
export const downSchArt = data => {
  return request.post('/admin/downSchArt', data)
}
// 恢复下架
export const backDownSchArt = data => {
  return request.post('/admin/backDownSchArt', data)
}
// 假删除
export const delSchArt = data => {
  return request.post('/admin/delSchArt', data)
}
// 找回删除
export const backDelSchArt = data => {
  return request.post('/admin/backDelSchArt', data)
}

// 真删除
export const rdelSchArt = data => {
  return request.post('/admin/rdelSchArt', data)
}
