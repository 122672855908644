<template>
<div class="foot row">
    <div class="container">
        <div class="foot-items">
            <div class="foot-item">
                <!-- <p>Copyright © 2024.软件工程&nbsp;版权所有 未经许可严禁复制<a href="https://liwy255.top" target="_blank">联系我们</a></p> -->
                <p>
                    Copyright © 2024.软件工程 Wenyue Li,Wenxue Sun &nbsp;
                    
                </p> 
            </div>
            <div class="foot-item">
                <p> 
                    <a href="https://liwy255.top" target="_blank">联系我们</a>&nbsp;
                    <a href="" @click.prevent.stop="$router.push('/question/71')">服务协议</a>&nbsp;
                    <a href="" @click.prevent.stop="$router.push('/question/72')">使用手册</a>&nbsp;
                    <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024059783号</a>
                </p>
            </div>
        </div>

        </div>
</div>
</template>

<script>
export default {
  name: 'foot'
}
</script>

<style>
.container{
    width: 100%;
    margin: 0 auto;
}
.row{
    display: flex;
    flex-direction: row;
}
.foot{

    background-color: #0ac247;
    width: 100%;
    height: 70px;
    /* position: absolute; */
    /* bottom: 0; */
    /*margin: 0 0 0 0 ;*/
}
.foot-item a{
    color:rgba(246, 243, 243, 0.868);
    padding-left: 0.08333em;
}
.foot-item a:hover{
    color:rgba(235, 56, 56, 0.6);
}
.foot-item{
    flex: 1;
    color: rgba(246, 236, 236, 0.956);
    margin: 0.2rem 0.8333rem;
}
.foot-items{
    text-align: center;
}

</style>
