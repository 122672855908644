<template>
  <div class="top">
    <div class="content">
      <!-- logo -->
      <div class="top_icon">
        <router-link to="/Home">
          <img src="../../src/assets/images/23.png" alt="">
        </router-link>
      </div>

      <div class="top_info">
        <ul>
          <li v-for="(item, index) in tab" @click="changeClass(index, item)" :class="{ red: classActives == index }"
            :key="index">
            <span v-if="item.external" @click="goToExternalLink(item.to)">{{ item.name }}</span>
            <router-link v-else :to="item.to">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>

      <div class="search">
         <span class="redHot"></span>
         <div class="input-container">
             <input type="text" placeholder="电子书" @keyup.enter="Search" v-model.trim="search" ref="SearchInput" > 
         </div>
        <button @click="Search" ref="searchBtn">
          <img src="../assets/images/搜索.png">
          <span>搜索</span>
        </button> 
      </div>

      <div class="user">
        <!-- 如果登录，则显示 -->
        <div class="userCenter" v-if="isLogin()">
          <div class="avator">
            <!-- 如果有头像，则显示用户设置头像 反之为默认头像 -->
            <img v-if="havaAvator()" :src="userInfo.photo">
            <img v-else src="../../public/images/toux.jpg">
          </div>
          <li @click="$router.push('/user')">
            <p>{{ userInfo.username }}</p>
          </li>
          <li @click="$router.push('/user/userSet')">
            <p>个人中心</p>
          </li>
          <li @click="backLogin">
            <p>退出</p>
          </li>
        </div>

        <div class="top_login" v-else>
          <button class="login" @click="Login">登录</button>
          <button class="register" @click="backregister">注册</button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { getUserInfo } from '../API/User.js'
import { search } from '../API/search.js'

export default {
  name: 'headNav',
  data() {
    return {
      backLoginIsShow: true,
      userInfo: {},
      currentPath: this.$route.path,
      searchObj: {
        trades: [],
        schArts: [],
        questions: []
      },
      classActives: null,
      avator: false,
      search: '',
      activa_status: '校园公告',
      tab: [
        {
          name: '首页',
          to: '/'
        },
        {
          name: '校园公告',
          to: '/schArt'
        },
        {
          name: '二手商品',
          to: '/trade'
        },
        {
          name: '问答交流',
          to: '/question'
        },
        {
          name: '休闲娱乐',
          to: 'http://huitailang.liwy255.top/',
          external: true
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['clearToken', 'updataUserInfo']),
    ...mapActions(['initUserInfo']),
    changeClass(index, item) {
      this.classActives = index
    },
    goToExternalLink(url) {
      window.location.href = url
    },
    backLogin() {
      this.clearToken()
      this.$router.go(0)
    },
    Login() {
      this.$router.push({
        path: '/login'
      })
    },
    backregister() {
      this.$router.push({
        path: '/login',
        query: {
          login: 'register'
        }
      })
    },
    isLogin() {
      if (JSON.stringify(this.userInfo)) {
        if (this.userInfo.username === undefined) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    havaAvator() {
      if (this.userInfo.photo !== null) {
        return true
      } else {
        return false
      }
    },
    async Search() {
      if (this.search !== '') {
        const path = this.$route.path
        if (path.indexOf('search') === -1) {
          this.$router.push({
            path: '/search',
            query: {
              keyword: this.search
            }
          })
        } else {
          this.$nextTick(async () => {
            const { data: res } = await search(this.search)
            this.searchObj.schArts = res.data[0].filter(item => {
              return item.isdelete === 0 && item.isdown === 0
            })
            this.searchObj.trades = res.data[1].filter(item => {
              return item.isdelete === 0 && item.isdown === 0
            })
            this.searchObj.questions = res.data[2].filter(item => {
              return item.isdelete === 0 && item.isdown === 0
            })
            this.$emit('searchDate', this.searchObj)
            this.$router.replace('/search?keyword=' + this.search)
          })
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '搜索内容不能为空'
        })
      }
    },
    init() {
      this.$nextTick(async () => {
        const { data: res } = await getUserInfo()
        if (res.status === 0) {
          this.userInfo = res.data
        }
      })
    },
    keydown(e) {
      const keyCode = e.keyCode
      if (keyCode === 13) {
        this.Search()
      }
    }
  },
  created() {
    this.initUserInfo()
    this.init()
    this.havaAvator()
    this.isLogin()
  },
  watch: {
    userInfo: {
      handler(newVal) {
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  color: #eee9e9;
  list-style: none;
}

a {
  text-decoration: none;
}

.top {
  width: 100%;
  //height: 1.375rem;
  height: 1.575rem;
  background: linear-gradient(to left, rgb(0, 86, 31), rgb(0, 255, 179));
  // position: fixed; /* 固定头部位置 */
  // top: 0; /* 紧贴顶部 */
  // z-index: 90; /* 确保头部在其他内容之上 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 可选，增加阴影效果 */
  // margin-bottom: 1.5rem;
  .content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

      .top_icon {
        flex: 1;
        justify-content: flex-start;
        display: flex;

        img {
          width: 2.1rem;
          height: 0.975rem;
        }
      }

      .top_info {
        flex: 9;
        display: flex;
        // align-items: center;
        ul {
          display: flex;
          // align-items: center;
          // margin-left: .3333rem;
          // padding:0.1rem;
          // margin-left: .3333rem;
          // flex-wrap: wrap;
          // justify-content: space-between;
          li a,
          li span {
            // padding: .1667rem;
            // padding: 0rem;
            // margin: 0 .0833rem;
            display: flex;
            margin-left: .1333rem;
            font-size: .2667rem;
            font-weight: 700;
            font-family: "Microsoft JhengHei", cursive;
            cursor: pointer;
            color: white;
            // flex-wrap: wrap;
            // justify-content: space-between;
            // text-align: center; 
          }

          li a:hover,
          li span:hover {
            color: #fd8b8b;
            font-weight: 600;
          }

          .red a {
            font-weight: 600;
            color: #ff3535 !important;
          }
        }
      }

      .search {
        flex: 7;
        position: relative;
        display: flex;
        align-items: center;
         /* 添加 Flex 布局相关属性 */
        // justify-content: space-between; /* 分散对齐，确保输入框与按钮之间有间隔 */
        // .redHot:before {
        //   position: absolute;
        //   width: .15rem;
        //   height: .15rem;
        //   background: url('../assets/images/hot.png') no-repeat;
        //   background-position: 0rem -0.15rem;
        //   left: .25rem;
        //   background-size: 200%;
        // }
        .input-container {
          position: relative;
          display: inline-block;
        }
        .input-container:before {
          content: "";
          position: absolute;
          top: 1em;
          left: 0;
          width: 1.8em; /* 图标宽度 */
          height: 1.8em;
          background-image: url('../assets/images/hot.png'); /* 图标路径 */
          background-size: cover; /* 确保图标填充整个伪元素 */
          background-repeat: no-repeat;
          background-position: center; /* 图标垂直居中 */
          border-radius: 2em 0 0 2em; /* 左侧圆角 */
        }
        .input-container input {
          font-size: 0.25rem;
          // display: inline-block;
          display: grid;
          grid-template-columns: 100px;
          // min-width: 0.9rem; /* 设置最小宽度 */
          // max-width: 4rem; /* 设置最大宽度，根据需要调整 */
          width: 100%;
          height: 0.95rem;
          // padding: 0em 0;
          border: 0 none;
          outline: 0;
          background: #f5f6f7;
          color: #222226;
          vertical-align: top;
          text-indent: 2.3em;
          border: 0 solid #e8e8ed;
          border-right: none;
          box-sizing: border-box;
          border-radius: 2em 0 0 2em;
        }

        button {
          display: inline-block;
          // width: 70px;
          width:1.3rem;
          height: 0.95rem;
          outline: 0;
          border: 0 none;
          border-radius: 0 1rem 1rem 0;
          font-size: 0.25rem;
          line-height: 0.35rem;
          // line-height: inherit;
          cursor: pointer;
          -webkit-transition: all .2s ease-in;
          transition: all .2s ease-in;
          background-color: #0092EE;
          text-align: left;

          img {
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            background-position: 0.6em;
            background-size: 100%;
            vertical-align: middle;
            position: relative;
            padding: 0 0.2em;
          }

          span {
            display: inline-block;
            vertical-align: top;
            color: #fff;
          }
        }

        button:hover {
          background-color: #0087FF;
        }
      }
    

  .top_login {
    flex: 5;
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    align-items: center;
    // padding-left: 0.5rem;
    .login {
      padding: .1667rem .4167rem;
      border: 0;
      background-color: #409EFF;
      border-radius: .3333rem;
      color: #fff;
      margin:0 .1667rem;
      cursor: pointer;
    }

    .login:hover {
      background-color: #0092EE;
    }

    .register {
      padding: .1667rem .4167rem;
      border: .0167rem solid #999;
      background-color: #FFF;
      border-radius: .3333rem;
      color: #000;
      margin: 0 .1667rem;
      cursor: pointer;
    }

    .register:hover {
      color: #fff;
      background-color: #409EFF;
    }
  }

    .user {
      display: flex;
      flex: 6;
      justify-content: center;
      align-items: center;
      padding-left: 0.2rem;

      .avator {
        width: 1rem;
        height: 1rem;

        img {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
          border-radius: 50%;
        }
      }

      li:hover {
        color: #0092EE
      }

      .userCenter {
        display: flex;
        align-items: center;

        li {
          cursor: pointer;
          margin-left: .3333rem;
          font-size: .2667rem
        }
      }
    } 
  }
}

</style>
