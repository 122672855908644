import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import ElementUI from 'element-ui'
import VueQuillEditor from 'vue-quill-editor'
import 'element-ui/lib/theme-chalk/index.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import 'lib-flexible'
import VueLazyload from 'vue-lazyload'
// 导入 dayjs 的核心模块
import dayjs from 'dayjs'
// 导入计算相对时间的插件
import relativeTime from 'dayjs/plugin/relativeTime'
// 导入中文语言包
import zh from 'dayjs/locale/zh-cn'
import { Loading } from "element-ui";
Vue.prototype.$loading = Loading.service;
Vue.use(ElementUI)
Vue.use(dayjs)
Vue.use(VueLazyload)
Vue.use(VueQuillEditor)
// 配置“计算相对时间”的插件
dayjs.extend(relativeTime)
Vue.prototype.trim = function (str) {
  var newStr = str.replace(/^\s*$/g, '')
  return newStr
}
// 配置中文语言包
dayjs.locale(zh)
// 生产模式
// Vue.config.productionTip = false
Vue.filter('dataFormat', dt => {
  // 调用 dayjs() 得到的是当前的时间
  // .to() 方法的返回值，是计算出来的“相对时间”
  return dayjs().to(dt)
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
