
import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfo } from '../API/User.js'
Vue.use(Vuex)
var initState = {
  userInfo: {},
  token: ''
}
const stateStr = localStorage.getItem('state')
// 加载本地数据
if (stateStr) {
  initState = JSON.parse(stateStr)
}
export default new Vuex.Store({
  state: initState,
  // state: initState,
  mutations: {
    // 保存token
    savaLocalStore (state) {
      localStorage.setItem('state', JSON.stringify(state))
    },
    upTokenInfo (state, el) {
      // console.log(el)
      state.token = el

      // 如果希望在 Mutation A 中调用 Mutation B，需要通过 this.commit() 方法来实现
      // this 表示当前的 new 出来的 store 实例对象
      this.commit('savaLocalStore')
    },
    updataUserInfo (state, payload) {
      state.userInfo = payload
      // console.log(payload)
      this.commit('savaLocalStore')
    },
    clearToken (state) {
      state.token = ''
      state.userInfo = {}

      // 2. 将清空后的 state 存储到本地
      this.commit('savaLocalStore')
    }
  },
  actions: {
    // 获取用户信息
    async initUserInfo (state) {
      const { data: res } = await getUserInfo()
      // console.log(res)
      if (res.status === 0) {
        this.commit('updataUserInfo', res.data)
      }
    },
    $base64Img (state, e) {
      return new Promise(function (resolve, reject) { // 使用Promise进行异步处理
        let fileSize = 0
        const fileMaxSize = 500 // 设置图片最大为 500k
        fileSize = e.target.files[0].size // 获取图片大小
        const size = fileSize / 1024
        const file = e.target.files[0] // 获取图片文件对象
        const reader = new FileReader() // 实例化一个对象
        if (size > fileMaxSize) {
          e.target.value = '' // 内容清空
          resolve({ message: '图片须小于500KB！', status: 1 })
        } else if (size <= 0) {
          e.target.value = '' // 内容清空
          resolve({ message: '图片大小大小不能为0M！', status: 1 })
        } else {
          reader.readAsDataURL(file) // 把图片文件对象转换base64
          reader.onload = function (e) {
            resolve({ data: e.target.result, message: '上传成功', status: 0 }) // 回到函数返回base64值
          }
        }
      })
    }

  }

})
