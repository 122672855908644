<template>
  <div id="app">
    <!-- <keep-alive>
      <router-view ></router-view>
    </keep-alive> -->
    <router-view></router-view>
    <!-- 返回顶部按钮 -->
    <button id="backToTop" title="返回顶部" @click="scrollToTop">返回顶部</button>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {};
  },
  methods: {
    scrollFunction() {
      const backToTopButton = document.getElementById('backToTop');
      if (document.documentElement.scrollTop > 20) {
        backToTopButton.style.display = 'block';
      } else {
        backToTopButton.style.display = 'none';
      }
    },
    scrollToTop() {
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollFunction);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #2c3e50;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  /* height: 100%; */
}

/* 返回顶部按钮的样式 */
#backToTop {
  display: none;
  /* 默认隐藏 */
  position: fixed;
  /* 固定在页面 */
  bottom: 40px;
  /* 距离底部20像素 */
  right: 30px;
  /* 距离右侧30像素 */
  z-index: 99;
  /* 确保按钮显示在最前面 */
  border: none;
  /* 无边框 */
  outline: none;
  /* 无轮廓 */
  background-color: #175511;
  /* 背景颜色 */
  color: white;
  /* 字体颜色 */
  cursor: pointer;
  /* 鼠标悬停时变为手指形状 */
  padding: 15px;
  /* 内边距 */
  border-radius: 10px;
  /* 圆角边框 */
}

#backToTop:hover {
  background-color: #7be10f;
  /* 悬停时背景颜色变化 */
}
</style>
