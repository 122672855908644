<template>
  <div>

    <Head></Head>

    <div class="block">
      <el-carousel trigger="click" height="425px">
        <el-carousel-item v-for="item in images" :key="item">
          <img :src="item">
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 商品部分 -->
    <keep-alive>
      <div class="main_shop">
        <div class="titles">
          <p>&nbsp 二手商品</p>
          <router-link to="/trade">更多</router-link>
        </div>
        <div class="lists">
          <ul>
            <li v-for="(item, index) in trades" :key="index">
              <div class="img" @mouseenter="imgUp" @mouseleave="imgDown"
                @click="$router.push('/trade/' + item.id, { params: { traId: item.id } })">
                <img :src="item.photo" ref="shopImg" v-if="item.photo" alt="">
                <img src="../../public/images/toux.jpg" v-else alt="">
              </div>
              <div class="introduce">
                <p><span>电子</span>{{ item.title }}</p>

              </div>
              <div class="pri">
                <p><span>￥</span>{{ item.price }}</p>
                <div class="imm" @click="$router.push('/trade/' + item.id, { params: { traId: item.id } })">
                  立即查看
                </div>
              </div>
            </li>
          </ul>

        </div>

      </div>
    </keep-alive>
    <!-- 校园公告 -->
    <div class="main">
      <div class="titles">
        <p>&nbsp 校园公告</p>
        <router-link to="/schArt">更多</router-link>
      </div>
      <div class="queInfo" v-for="(item, index) in schArts" :key="index"
        @click="$router.push('/schArt/' + item.id, { params: { schArtId: item.id } })">
        <div class="top">
          <div class="title">
            <h3>{{ item.title }} <span v-show="item.isdown === 0 ? false : true">已下架</span></h3>
            <!-- <p>￥<span>{{item.price}}</span></p> -->
          </div>
          <div class="data">
            <p><span>发表时间:</span>{{ item.pubdata | dataFormat }}</p>
          </div>
        </div>
        <!-- <div class="content">
          <p v-html="item.content"></p>
        </div> -->
        <!-- <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
            </div>
          </div>
    </div> -->
        <div class="info">
          <div class="views">
            <ul>
              <li><i class="view-icon"></i><span>{{ item.view }}</span></li>

            </ul>
          </div>
          <div class="edit">
            <ul>
              <li @click="$router.push('/schArt/' + item.id, { params: { schArtId: item.id } })">查看详情</li>

            </ul>

          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from '../components/head.vue'
import Foot from '../views/foot.vue'
// import banner0 from '../assets/images/banner/0.jpg'
import banner1 from '../assets/images/banner/1.jpg'
import banner2 from '../assets/images/banner/3.jpg'
import banner3 from '../assets/images/banner/2.jpg'
import banner4 from '../assets/images/banner/4.jpeg'
import banner5 from '../assets/images/banner/5.jpg'
import banner6 from '../assets/images/banner/6.jpg'
import { getSchArtApi } from '../API/schArt.js'
import { getTrade } from '../API/Trade.js'
export default {

  name: 'Home',
  data() {
    return {
      images: [banner4, banner1, banner6, banner2, banner5, banner3],
      schArts: [],
      trades: [],
      isShowImg: false,
    }
  },
  methods: {
    async getSchArts() {
      const { data: res } = await getSchArtApi()
      var count = 0
      if (res.status === 0) {
        res.data.map((item, index, arr) => {
          // 已发布列表(包含既未下架也未删除)
          // 取前三个

          if (item.isdelete === 0 && item.isdown === 0) {
            if (count < 3) {
              this.schArts = [...this.schArts, item]
              count++
            } else {
              return false
            }
          }
        })
      }
    },
    // 获取所有文章
    showSch(e) {
      // console.log(e)
    },
    async getTrades() {
      const { data: res } = await getTrade()
      var count = 0
      if (res.status === 0) {
        res.data.map((item, index, arr) => {
          // 已发布列表(包含既未下架也未删除)
          if (item.isdelete === 0 && item.isdown === 0) {
            if (count < 4) {
              this.trades = [...this.trades, item]
              count++
            } else {
              return false
            }
          }
        })
      }
    },
    imgUp(e) {
      // console.log(e)
      this.$refs.shopImg.style = 'transform: translateY(-15px);'
    },
    imgDown(e) {
      // console.log(e + '1')
      this.$refs.shopImg.style = 'transform: translateY(0px);'
    }
  },

  components: {
    Head,
    Foot
  },
  activated() {

  },
  created() {
    this.getSchArts()
    this.getTrades()
  }

}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.slider {
  width: 100%;

  .logo {
    width: 100%;
    height: 510px;
    background: url('../assets/images/banner/6.jpg') no-repeat;
    background-size: 100%;
  }
}

.el-carousel__item img {
  color: #475669;
  font-size: 14px;
  //opacity: 0.75;
  // width: 86.8%;
  height: 425px;
  width:100%;
  position: relative;
  object-fit: cover;
  line-height: 200px;
  // margin: 0 100px;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
  background-color: white;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: white;
}

.main {
  width: 87%;
  margin: 30px auto;

  .titles {
    width: 100%;
    margin: 20px auto;
    display: flex;
    padding: 15px 0;
    border-bottom: 2.5px solid green;
    justify-content: space-between;

    p {
      font-size: 25px;
      font-weight: 700;
      color: green;
      border-left: 2.5px solid green;
    }

    a {
      font-size: 16px;
      color: #757373;
    }

    a:hover {
      color: #e71616;
    }
  }

  .queInfo {
    overflow-x: hidden;
    background-color: #fff;
    border-bottom: 1px solid #dbdbdf;
    cursor: pointer;

    .top {
      display: flex;
      justify-content: space-between;
      padding: 5px 25px;

      .title {
        display: flex;
        align-items: center;

        p {
          margin: 0 20px;
          color: #FF3535;
          font-size: 24px;

        }

        h3 {
          span {
            color: #d47171;
          }
        }
      }

      .data {
        min-width: 142px;
        text-align: right;
        height: 24px;
        line-height: 24px;
      }
    }

    .content {
      padding: 5px 25px;
      height: 60px;
      p {
        // width: 430px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-height: 30px;
        -webkit-box-orient: vertical;
        text-indent: 12px;
      }
    }

    .info {
      display: flex;
      padding: 5px 20px;
      justify-content: space-between;

      .views {
        ul {
          display: flex;

          li {
            margin: 0 5px;

            i {
              display: inline-block;
              width: 16px;
              height: 16px;
              vertical-align: bottom;
              margin-right: 8px;
              background-size: 16px !important;
            }

            .view-icon {
              background: url('../../public/images/浏览.png') no-repeat;
            }

            .like-icon {
              background: url('../../public/images/点赞.png');
            }

            .comment-icon {
              background: url('../../public/images/消息.png');
            }
          }
        }
      }

      .edit {
        ul {
          display: flex;

          li {
            margin: 0 5px;
            cursor: pointer;
          }

          li:hover {

            color: #ff3535;
          }
        }
      }
    }
  }

  .queInfo:hover {
    background-color: #f3f3f3;
  }
}

.main_shop {
  width: 87%;
  margin: 30px auto;

  .titles {
    width: 100%;
    margin: 20px auto;
    display: flex;
    padding: 15px 0;
    border-bottom: 2.5px solid green;
    justify-content: space-between;

    p {
      font-size: 25px;
      font-weight: 700;
      color: green;
      border-left: 2.5px solid green;
    }


    a {
      font-size: 16px;
      color: #757373;
    }

    a:hover {
      color: #e71616;
    }
  }

  .lists {
    width: 100%;

    ul {
      display: flex;
      width: 100%;
      flex-flow: wrap;

      li {
        height: 100%;
        width: 300px;
        margin: 5px;
        background-color: #fff;

        .img {
          width: 100%;
          height: 196px;
          padding: 10px;
          cursor: pointer;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;

          img {
            // width: 80%;
            height: 100%;
            overflow: hidden;
          }
        }

        .introduce {
          width: 90%;
          margin: 10px auto;

          p {
            margin: 20px 0 10px 0;
            font-size: 16px;

            span {
              display: inline-block;
              padding: 1px 5px;
              border: 1px solid #ff3535;
              color: #ff3535;
              margin: 0 5px;
              font-size: 12px;
            }
          }
        }

        .pri {
          display: flex;
          margin: 20px 0;
          justify-content: space-around;

          p {
            font-size: 20px;
            color: #ff3535;
            line-height: 40px;

            span {
              font-size: 16px;
            }
          }

          .imm {
            color: #fff;
            height: 35px;
            line-height: 35px;
            font-size: 14px;
            display: block;
            cursor: pointer;
            margin: auto 0;
            width: 75px;
            text-align: center;
            background: #df0021;

          }

          .imm:hover {
            color: rgba(255, 255, 255, .9)
          }
        }
      }
    }
  }

  .bottom {
    margin: 20px 0;

    p {
      text-align: center;
      font-size: 24px;
      color: #969090
    }
  }
}
</style>
