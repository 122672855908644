import request from '../until/request.js'

// 发布二手信息
export const addTrade = data => {
  return request.post('/admin/addTrade', data)
}
// 编辑二手信息
export const editTrade = data => {
  return request.post('/admin/editTrade', data)
}
// 获取用户发表的二手信息列表
export const getUserPubTrade = data => {
  return request.get('/admin/getUserPubTrade')
}

// 获取用户发表的二手信息详情
export const getDetailTrade = data => {
  return request.get('/admin/getDetailTrade?id=' + data)
}
// 真删除
export const rDelTrade = data => {
  return request.post('/admin/rDelTrade', data)
}

// 假删除
export const delTrade = data => {
  return request.post('/admin/delTrade', data)
}

// 恢复假删除的
export const backDelTrade = data => {
  return request.post('/admin/backDelTrade', data)
}
// 下架二手信息
export const downTrade = data => {
  return request.post('/admin/downTrade', data)
}
// 恢复下架的二手信息
export const backDownTrade = data => {
  return request.post('/admin/backDownTrade', data)
}

// api
// 访客 获取所有二手信息
export const getTrade = data => {
  return request.get('/api/getTrade')
}
// 访客 获取所有二手信息图片
export const getTradePhoto = data => {
  return request.get('/api/getTradePhoto')
}
// 访客 获取二手信息详情
export const getTradeDetail = data => {
  return request.get('/api/getDetailTrade?id=' + data)
}
