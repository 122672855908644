import request from '../until/request.js'
// 注册
export const register = data => {
  return request.post('/api/register', data)
}
// 登录
export const login = data => {
  return request.post('/api/login', data)
}
// 获取用户信息
export const getUserInfo = data => {
  return request.get('/admin/getUserInfo')
}
//获取学院信息
export const getDep = data=>{
  return request.get('/api/getDep?id='+data)
}

// 修改用户资料
export const setUserInfo = data => {
  return request.post('/admin/setUserInfo')
}
// 更换用户头像
export const changeAvater = data => {
  return request.post('/admin/changeAvator', data)
}

// 校园认证
export const schToken = data => {
  return request.post('/admin/schToken', data)
}

// 修改密码
export const changePsd = data => {
  return request.post('/admin/changePsd', data)
}
// 修改学校名称
export const changeSch = data => {
  return request.post('/admin/changeSchool', data)
}
// 修改学院名称
export const changeDep = data => {
  return request.post('/admin/changeDep', data)
}
// 修改学号
export const changeStuId = data => {
  return request.post('/admin/changeStuId', data)
}
export const changeMotto = data => {
  return request.post('/admin/changeMotto', data)
}
// 修改性别
export const changeSex = data => {
  return request.post('/admin/changeSex', data)
}
// 修改生日
export const changeBirth = data => {
  return request.post('/admin/changeBirth', data)
}
// 修改用户名
export const changeUserName = data => {
  return request.post('/admin/changeUserName', data)
}
// 匹配账号
export const matchUser = data => {
  return request.post('/api/matchUser', data)
}
// 重置密码
export const recoveryPsd = data => {
  return request.post('/api/recoveryPsd', data)
}
