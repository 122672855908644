import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originPush = VueRouter.prototype.push

VueRouter.prototype.push = function (location) {
  return originPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: {
    //   keepAlive: true,
    // }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/Home',
    component: Home,
    meta: {
      keepAlive: true,
    }
    // meta: { keepAlive: true,}
     
    

    // path 为"空字符串"的子路由规则，叫做"默认子路由"
    // { path: '', component: () => import('../views/Home.vue'), name: 'Home', meta: { isRecord: true, top: 0 } },

  },
  {
    path: '/user',

    component: () => import('../views/user.vue'),
    meta: {
      keepAlive: true,
    },
    children: [

      { path: '', component: () => import('../components/user/userInfo.vue') },
      { path: 'userInfo', component: () => import('../components/user/userInfo.vue') },
      {
        path: 'userSet',
        component: () => import('../components/user/userSet.vue')

      },
      { path: 'schToken', component: () => import('../components/user/schToken.vue') },
      {
        path: 'userTrade',
        component: () => import('../components/user/userTrade.vue'),
        // meta: { keepAlive: true},
        children: [

          { path: 'addTrade', component: () => import('../components/user/pub/pubTrade.vue') },
          { path: 'editTrade/:traId', 
            name:'editTradeRoute',
            component: () => import('../components/user/pub/editTrade.vue') ,
            props: true,
            meta: {
              keepAlive: false,
              notCache: true // 关闭缓存
            }
        },

        ]
      },

      {
        path: 'userQuestion',
        component: () => import('../components/user/userQuestion.vue'),
        // meta: { keepAlive: true},
        children: [

          { path: 'addQuestion', component: () => import('../components/user/pub/pubQuestion.vue') },
          { path: 'editQuestion/:queId', 
            name:'editQuestionRoute',
            component: () => import('../components/user/pub/editQuestion.vue') ,
            props: true,
            meta: {
              keepAlive: false,
              notCache: true // 关闭缓存
            }
        },
        ]
      },
      {
        path: 'userFeedback',
        component: () => import('../components/user/userFeedback.vue'),
        meta: { keepAlive: true},
        children: [

          { path: 'addFeedback', component: () => import('../components/user/pub/pubFeedback.vue') },
        ]
      },
      {
        path: 'userCollect',
        component: () => import('../components/user/userCollect.vue'),
        meta: { keepAlive: true},
      },
      { path: 'MUser', component: () => import('../components/manager/MUser.vue') ,meta: { keepAlive: true},},
      {
        path: 'MSch',
        component: () => import('../components/manager/MSch.vue'),
        meta: { keepAlive: true},
        children: [

          { path: 'addSchArt', component: () => import('../components/user/pub/pubSchArt.vue') },
          { path: 'editSchArt/:schArtId', 
            name:'editSchArtRoute',
            component: () => import('../components/user/pub/editSchArt.vue') ,
            props: true,
            meta: {
              keepAlive: false,
              notCache: true // 关闭缓存
            }
        },
        ]
      },
      { path: 'MTrade', component: () => import('../components/manager/MTrade.vue'), },
      { path: 'MQuestion', component: () => import('../components/manager/MQuestion.vue'), },
      { path: 'MFeedback', component: () => import('../components/manager/MFeedback.vue'),},
      { path: 'MMUser', component: () => import('../components/manager/MMUser.vue'), },
      { path: 'TradeDetail', component: () => import('../components/Trade/tradeDetail.vue') }
    ],
    meta: { title: 'sideMenu.myCustomer.info', keepAlive: true, hidden: true, permission: ['table'] }
  },
  {
    path: '/trade',
    name: 'Trade',
    component: () => import('../components/Trade/Trade.vue'),
    meta: {
      keepAlive: true,
      // depth: 1
    }
  },
  {
    path: '/trade/:id',
    name: 'tradeDetail',
    component: () => import('../components/Trade/tradeDetail.vue'),
    props: true,
    meta: {
      keepAlive: false,
      notCache: true, // 关闭缓存
      scollTopPostion: true
    }
  },
  {
    path: '/schArt',
    component: () => import('../components/schArticle/schArt.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/schArt/:schArtId',
    component: () => import('../components/schArticle/schArtDetail.vue'),
    props: true,
    meta: {
      keepAlive: false,
      notCache: true // 关闭缓存
    }
  },
  {
    path: '/question',
    component: () => import('../components/question/question.vue'),
    meta: {
      keepAlive: false,
      notCache:true,
      // depth: 1
    }
  },
  {
    path: '/question/:queId',
    component: () => import('../components/question/questionDetail.vue'),
    props: true,
    meta: {
      keepAlive: false,
      notCache: true // 关闭缓存
    }
  },
  {
    path: '/feedback/:feedId',
    component: () => import('../components/feedback/feedbackDetail.vue'),
    props: true,
    meta: {
      keepAlive: false,
      notCache: true // 关闭缓存
    }
  },
  {
    path: '/search', // 搜索
    component: () => import('../views/search.vue')
  },
  {
    path: '/forgetPsd', // 忘记密码
    component: () => import('../views/forgetPsd.vue'),
    meta: {
      keepAlive: true,
      // depth: 1
    }
  }

]

const router = new VueRouter({
  routes
})
var pageItem = 'user'
var Manger = 'M'
router.beforeEach((to, from, next) => {
  // 获取本地token
  const token = store.state.token
  const userInfo = store.state.userInfo
  // 如果访问权限页面

  // 访问有权限项目
  if (to.path.indexOf(pageItem) !== -1) {
    if (token !== '') {
      next()
    } else {
      next(`/login?pre=${to.fullPath}`)
    }
    // 访问无权限页面
  } else if (to.path.indexOf(Manger) !== -1) {
    if (token !== '' && userInfo.uid === 0) {
      next()
    } else {

    }
  } else {
    next()
  }
})
export default router
