import axios from 'axios'

import store from '../store/index.js'
const https = require('https'); //https
const request = axios.create({
  //http版本
  // baseURL: 'http://liwy255.top:8089'
  // 服务器部署，协议，域名，端口号一定要写对，调了好多，哭了
  // https版本，域名+端口号
  baseURL: 'https://schoolinfo.liwy255.top:8089'
  // 本地调试
  // baseURL: 'http://localhost:8089'
})
// config  发起请求的信息
request.interceptors.request.use(config => {
  //   获取本地的token信息

  const tokenStr = store.state.token
  const fullUrl = `${config.baseURL}${config.url}`;

  // 输出完整的请求路径到控制台,检测URL是否正确
  console.log(`Sending request to: ${fullUrl}`);
  //   如果本地存在token,则请求时带上token
  if (tokenStr) {
    // 3. 添加身份认证字段
    config.headers.Authorization = tokenStr
  }
  return config
}, error => {
  return Promise.reject(error)
}
)

// request.interceptors.response.use(response => {
//   return response
// },
// error => {
//   // 如果有响应的结果，并且响应的状态码是 401，则证明 Token 过期了
//   if (error.response && error.response.state === 401) {
//     console.log('token过期了')

//     // TODO1：清空 vuex 和 localStorage 中的数据
//     store.commit('clearToken')
//     // TODO2：强制跳转到登录页，并通过路由的 query 查询参数，把当前用户访问未遂的路由地址带给登录页
//     // router.replace('/login?pre=' + router.currentRoute.fullPath)
//   }
//   return Promise.reject(error)
// }

export default request
